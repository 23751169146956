<template>
  <div class="min-h-screen flex flex-col bg-gradient-to-b from-blue-500 to-blue-300 relative overflow-hidden">
    <!-- Navbar Component -->
    <Navbar />

    <!-- Hero Section -->
    <div class="flex flex-col items-center justify-center flex-grow text-white text-center px-6 relative z-20">
      <!-- Slogan -->
      <h1 class="text-5xl font-bold mb-6 drop-shadow-md">
        Stay Ahead of the Swell.
      </h1>
      <p class="text-2xl font-medium mb-10 drop-shadow-md">
        Free, Live Surf Data.
      </p>

      <!-- Home Beach Selector Component -->
      <div class="w-96 max-w-full sm:w-80 relative z-30">
        <HomeBeachSelector />
      </div>

    </div>

    <!-- Animated Decorative Waves -->
    <div class="absolute bottom-0 left-0 w-full h-80 z-10">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1440 390"
        xmlns="http://www.w3.org/2000/svg"
        class="transition duration-300 ease-in-out delay-150"
        preserveAspectRatio="none"
      >
        <path
          d="M 0,400 L 0,150 C 42.35072142064372,166.41238130472314 84.70144284128745,182.82476260944628 132,175 C 179.29855715871255,167.17523739055372 231.54495005549393,135.11333086693796 294,136 C 356.4550499445061,136.88666913306204 429.1187569367369,170.72191392280183 484,189 C 538.8812430632631,207.27808607719817 575.9800221975582,209.99901344185474 627,186 C 678.0199778024418,162.00098655814526 742.9611542730299,111.28203230977924 807,91 C 871.0388457269701,70.71796769022076 934.1753607103219,80.87285731902824 983,109 C 1031.824639289678,137.12714268097176 1066.3374028856829,183.2265384141078 1112,189 C 1157.6625971143171,194.7734615858922 1214.4750277469477,160.22098902454061 1271,147 C 1327.5249722530523,133.77901097545939 1383.7624861265263,141.8895054877297 1440,150 L 1440,400 L 0,400 Z"
          stroke="none"
          stroke-width="0"
          fill="#0693e3"
          fill-opacity="1"
          class="path-0"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import Navbar from './HomeNavbar.vue';
import HomeBeachSelector from "@/components/HomeBeachSelector.vue";

export default {
  name: 'HomePage',
  components: {
    Navbar,
    HomeBeachSelector,
  },
};
</script>

<style scoped>
/* Import Plus Jakarta Sans font */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800&display=swap');

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

h1 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

p {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.path-0 {
  animation: pathAnim-0 4s linear infinite;
}

@keyframes pathAnim-0 {
  0% {
    d: path("M 0,400 L 0,150 C 42.35072142064372,166.41238130472314 84.70144284128745,182.82476260944628 132,175 C 179.29855715871255,167.17523739055372 231.54495005549393,135.11333086693796 294,136 C 356.4550499445061,136.88666913306204 429.1187569367369,170.72191392280183 484,189 C 538.8812430632631,207.27808607719817 575.9800221975582,209.99901344185474 627,186 C 678.0199778024418,162.00098655814526 742.9611542730299,111.28203230977924 807,91 C 871.0388457269701,70.71796769022076 934.1753607103219,80.87285731902824 983,109 C 1031.824639289678,137.12714268097176 1066.3374028856829,183.2265384141078 1112,189 C 1157.6625971143171,194.7734615858922 1214.4750277469477,160.22098902454061 1271,147 C 1327.5249722530523,133.77901097545939 1383.7624861265263,141.8895054877297 1440,150 L 1440,400 L 0,400 Z");
  }
  25% {
    d: path("M 0,400 L 0,150 C 57.02172894314958,136.73690960660994 114.04345788629917,123.47381921321988 168,140 C 221.95654211370083,156.52618078678012 272.8478973979529,202.8416327537304 330,192 C 387.1521026020471,181.1583672462696 450.56495252188915,113.15964977185843 499,110 C 547.4350474781108,106.84035022814157 580.8922925144902,168.51976815883586 630,184 C 679.1077074855098,199.48023184116414 743.8658774201505,168.76127759279814 806,155 C 868.1341225798495,141.23872240720186 927.6441978049081,144.43512146997162 972,141 C 1016.3558021950919,137.56487853002838 1045.557331360217,127.49823652731533 1103,137 C 1160.442668639783,146.50176347268467 1246.1264767542239,175.57193242076704 1307,181 C 1367.8735232457761,186.42806757923296 1403.936761622888,168.21403378961648 1440,150 L 1440,400 L 0,400 Z");
  }
  50% {
    d: path("M 0,400 L 0,150 C 56.58473301270193,163.88753237143914 113.16946602540386,177.7750647428783 172,176 C 230.83053397459614,174.2249352571217 291.90686891108646,156.787273399926 334,138 C 376.09313108891354,119.212726600074 399.2030583302503,99.07584165741768 456,93 C 512.7969416697497,86.92415834258232 603.2808977679123,94.90935997040326 663,93 C 722.7191022320877,91.09064002959674 751.6733505981008,79.2867184609693 800,76 C 848.3266494018992,72.7132815390307 916.0256998396842,77.94376618571954 978,103 C 1039.9743001603158,128.05623381428046 1096.223850043162,172.93821679615246 1144,190 C 1191.776149956838,207.06178320384754 1231.0788999876681,196.3033666296707 1279,185 C 1326.9211000123319,173.6966333703293 1383.460550006166,161.84831668516466 1440,150 L 1440,400 L 0,400 Z");
  }
  /* Remaining keyframes omitted for brevity */
}
</style>
